<svelte:options tag="top-carousel"/>

<script>
    import { onMount } from 'svelte';
    export let _images = topimages;


  let activeIndex = 0;

  let isDragging = false;
  let startPosition = null;

  onMount(() => {
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
  });

  function handleTouchStart(event) {
    isDragging = true;
    startPosition = event.touches[0].clientX;
  }

  function handleTouchMove(event) {
    if (!isDragging) return;
    const currentPosition = event.touches[0].clientX;
    const difference = startPosition - currentPosition;

    if (difference > 0) {
      // Swipe til venstre
      if (activeIndex < _images.length - 1) {
        activeIndex++;
      }
    } else {
      // Swipe til høyre
      if (activeIndex > 0) {
        activeIndex--;
      }
    }

    isDragging = false;
    startPosition = null;
  }

  function handleTouchEnd(event) {
    isDragging = false;
    startPosition = null;
  }

  function handleThumbnailClick(index) {
    activeIndex = index;
  }
</script>

<style>
    @import '/static/website/bundle.css';
    @import "https://kit.fontawesome.com/62abe87950.css";
</style>

<div class="component__top-carousel">
  <div class="top-carousel__images">
    {#each _images as image, index}
      {#if index === activeIndex}
        <picture src="{image.image_desktop}" alt="{image.image_alt}">
            <source media="(min-width:800px)" srcset="{image.image_desktop}">
            <source media="(min-width:600px)" srcset="{image.image_tablet}">
            <img src="{ image.image_mobile }" class="top-carousel__image" alt="{image.image_alt}"
            />
        </picture>
      {/if}
    {/each}
  </div>

  <div class="top-carousel__thumbnails">
    {#each _images as image, index}
        <button
            class="top-carousel__thumbnail"
            style="background-image:url('{image.image_thumbnail}')"
            alt="Klikk for å se stort bilde"
            on:click={() => handleThumbnailClick(index)}
            on:keypress={() => handleThumbnailClick(index)}
            tabindex="0"
        ></button>
    {/each}
  </div>
</div>
